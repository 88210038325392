(function webpackUniversalModuleDefinition(root, factory) {
	//Test Comment
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	//Test Comment
	else if(typeof define === 'function' && define.amd)
		define([], factory);
	//Test Comment
	else if(typeof exports === 'object')
		exports["CHUI"] = factory();
	//Test Comment
	else
		root["CHUI"] = factory();
})(window, function() {
return 